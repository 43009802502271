import React from 'react';

const checkOrientatien = () => {
  if (window.innerWidth > window.innerHeight) {
    return 'landscape';
  } else {
    return 'potrait';
  }
};

const redirectToMainSite = () => {
  window.location = 'https://codm.garena.co.id/';
};

const Home = () => {
  return (
    <div className={`home ${checkOrientatien()}`}>
      <div className={`home-content content-${checkOrientatien()}`}>
        <img
          className="logo"
          src={require('../assets/images/Logo_CODM.png')}
          alt="logo"
        />
        <div className="desc">
          <p>Mohon maaf website sedang dalam proses maintenance</p>
          <p>Silahkan coba lagi nanti</p>
        </div>
        <div className="button" onClick={redirectToMainSite} />
      </div>
    </div>
  );
};

export default Home;
