import axios from 'axios';

const cdn = axios.create({
    baseURL: `https://cdngarenanow-a.akamaihd.net/webid/Localize/${process.env.REACT_APP_LOCALIZE_NAME}/`,
    withCredentials: false,
    transformRequest: [(data, headers) => {
        delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME]
        return data
    }]
})

export const toggle_loader = (data) => ({
    type: 'TOGGLE_LOADER',
    data : data
})

export const put_data = (key, data) => ({
    type: "PUT_DATA",
    key,
    data
})

export const login = () => {
    return dispatch => {
        dispatch(toggle_loader(true))

        axios.get(process.env.REACT_APP_API_ENDPOINT)

        .then((resp) => {
            dispatch(put_data('login', resp.data))
        })
        .catch((err) => {
            console.log(err)
        })
        .then(()=>{
            dispatch(toggle_loader(false))
        })
    }
}

export const get_translate = (lang) => {
    return dispatch => {
        cdn.get(`${lang}.json?${Math.random() * 10000}`)
        .then(resp => {
            dispatch(put_data('lang_json', resp.data))
        })
        .catch(err => {

        })
    }
}